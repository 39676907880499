import React from "react"
import styles from './index.module.css'

import MainLayout from "../layouts/MainLayout"
import SEO from "../components/seo"

const SupportPage = () => {
  return (
    <MainLayout>
      <SEO title="Support" />
      <div className={styles.section}>
        <p>
        If you have any questions about Crowdlever, or would like help setting up Crowdlever for your website, you can contact support via email: <a href="mailto:support@crowdlever.io">support@crowdlever.io</a>
        </p>
      </div>
    </MainLayout>
  )
}

export default SupportPage